import { isPast, isFutureTask } from '@/utils/item'

export const TIME_PERIOD = [
    {
        title: 'Past',
        filter: isPast,
    },
    {
        title: 'Current',
        filter: (...params: Parameters<typeof isPast>) => !isPast(...params) && !isFutureTask(...params),
    },
    {
        title: 'Future',
        filter: isFutureTask,
    },
]
